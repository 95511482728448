import React from "react";
import "./categorie.scss";
import Badge from "react-bootstrap/Badge";
import APIHandler from "../../../Handler/APIHandler";
import Item from "./item";
import SubCategorie from "./subcategorie";

class Categorie extends React.Component {
	state = {
		expanded: false,
	};

	componentDidMount() {
		this.setState({
			available_count: this.props.data.available,
		});
	}

	render() {
		return (
			<div className="categorie">
				<div className="categorie-header">
					<div class="overlay">
						<h1>{this.props.data.name}</h1>
						<span>{this.props.data.description}</span>
						<br />
					</div>
				</div>
				<div className="categorie-body">
					<div className="categorie-body-items">
						{this.props.data.sub_categories.map((subCategorie) => (
							<SubCategorie 
							last = {this.props.data.sub_categories.length - 1 === this.props.data.sub_categories.indexOf(subCategorie)}
							reservate={(item, success) => this.props.reservate(item, success)} 
							unreservate={(item) => this.props.unreservate(item)}
							data={subCategorie} 
							reserved_items = {this.props.reserved_items} 
							/>
						))}
					</div>
				</div>
			</div>
		);
	}
}

export default Categorie;
