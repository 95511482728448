import React from "react";
import Item from "../../components/Reservation/Item/item";
import Notification from "../../components/Reservation/Item/Misc/Notification/notification";
import Service from "../../components/Reservation/Item/service";
import APIHandler from "../../Handler/APIHandler";
import "./reservation.scss";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Categorie from "../../components/Reservation/Item/categorie";
import { Table, Column, ButtonGroup, ButtonIcon, Badge } from 'react-rainbow-components';
import ReservateDialog from "../../components/Dialogs/Reservation/Reservate/ReservateDialog";

let notification = new Notification();

const StatusBadge = ({ value }) => {
	return <span className="rainbow-m-left_small">{value + " €"}</span>;
};

class Reservation extends React.Component {
	state = {
		items: null,
		services: [],
		reserved_items: [],
		reserved_services: [],
		categories: [],
		page: 0,
		reservationOpen: false,
		reservated:false
	};




	reservate = (item, success) => {
		if (success) {
			this.showMessage(
				item.name + " wurde zu deiner Anfrage hinzugefügt",
				"success"
			);

			if (item.required_services.length > 0) {
				item.required_services.forEach((service) => {
					let foundService = this.state.services.filter((s) => s.service_id == service.service_id);
					console.log(foundService);
					if (!this.state.reserved_services.includes(foundService[0])) {
						this.reservateService(foundService[0], true);
					}
				});
			}

			console.log(item);
			if (item.bundle) {
				this.forceUpdate();
			}

			console.log(item);

			this.setState(
				(prevState) => ({
					reserved_items: [...prevState.reserved_items, item],
				}),
				() => {
					console.log(this.state.reserved_items);
				}
			);
		} else {
			this.showMessage("Dieser Artikel ist akutell nicht verfügbar", "error");
		}
	};

	unreservate = async (item) => {
		this.showMessage(
			item.name + " wurde aus deiner Anfrage entfernt",
			"error"
		);

		console.log(item);

		if (item.required_services.length > 0) {
			for (let i = 0; i < item.required_services.length; i++) {
				let foundService = this.state.services.filter((s) => s.service_id == item.required_services[i].service_id);
				console.log(foundService);
				await this.removeService(foundService[0]);
			}
		}


		if (this.state.reserved_items.length === 1) {
			this.setState({
				reserved_items: [],
			});
		}

		let tempArr = this.state.reserved_items;

		tempArr.splice(
			tempArr.indexOf(tempArr.find((i) => i.device_id === item.device_id)),
			1
		);

		this.setState(
			(prevState) => ({
				reserved_items: tempArr,
			}),
			() => {
				console.log(this.state.reserved_items);
			}
		);
	};

	reservateService = (service, success) => {
		if (success) {
			this.showMessage(
				service.name + " wurde zu deiner Anfrage hinzugefügt",
				"success"
			);

			this.setState((prevState) => ({
				reserved_services: [...prevState.reserved_services, service],
			}), () => { console.log(this.state.reserved_services); });
		} else {
			this.showMessage("Dieser Artikel ist akutell nicht verfügbar", "error");
		}
	};

	removeService = async (service, success) => {
		this.showMessage(
			service.name + " wurde aus deinem Warenkorb entfernt",
			"error"
		);
		let services = this.state.reserved_services.filter(
			(arrService) => arrService.service_id != service.service_id
		);
		console.log(services);
		this.setState({
			reserved_services: services,
		});
	};

	showMessage = (message, type) => {
		notification.set(message, type, 2400);
	};

	componentDidMount() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		};

		fetch(APIHandler.URL + "/categories/getAllCategories", requestOptions)
			.then((response) => response.json())
			.then((data) => {
				this.setState(
					{
						categories: data,
					},
					() => { }
				);
			});
		fetch(APIHandler.URL + "/devices/getAllItems", requestOptions)
			.then((response) => response.json())
			.then((data) => {
				this.setState(
					{
						items: data,
					},
					() => {  }
				);
			});
		fetch(APIHandler.URL + "/services/getAllItems", requestOptions)
			.then((response) => response.json())
			.then((data) => {
				this.setState(
					{
						services: data,
					},
					() => { }
				);
			});
	}

	render() {
		let price = 0;
		let devices = 0;
		let price_services = 0;

		if (this.state.reserved_items != null) {
			this.state.reserved_items.forEach((item) => {
				price += item.price;
				devices += 1;
			});
		}

		if (this.state.reserved_services != null) {
			this.state.reserved_services.forEach((service) => {
				price_services += service.price;
			});
		}

		let page_0 =
			this.state.categories != null ? (
				<div>
					<div className="header">
						<div className="branding">
							<h4 className="title-desktop">Anfrage</h4>
						</div>
					</div>
					<center>
						<div className="reservation-progress-bar">
							<ProgressBar
								percent={0}
								filledBackground="linear-gradient(to right, #828382, #2ecc71)"
							>
								<Step transition="scale">
									{({ accomplished, index }) => (
										<div className="step-container">
											<div
												className={`indexedStep ${accomplished ? "accomplished" : ""
													}`}
											>
												{index + 1}
											</div>
											<span>Geräte</span>
										</div>
									)}
								</Step>
								<Step transition="scale">
									{({ accomplished, index }) => (
										<div className="step-container">
											<div
												className={`indexedStep ${accomplished ? "accomplished" : ""
													}`}
											>
												{index + 1}
											</div>
											<span>Services</span>
										</div>
									)}
								</Step>
								<Step transition="scale">
									{({ accomplished, index }) => (
										<div className="step-container">
											<div
												className={`indexedStep ${accomplished ? "accomplished" : ""
													}`}
											>
												{index + 1}
											</div>
											<span>Abschluss</span>
										</div>
									)}
								</Step>
							</ProgressBar>
						</div>
					</center>
					<div className="trends-title">
						<hr className="seperator-home"></hr>
					</div>
					<div className="item-list">
						{this.state.categories.map((categorie, index) => (
							<Categorie
								unreservate={(item) => this.unreservate(item)}
								reservate={(item, success) => this.reservate(item, success)}
								data={categorie}
								reserved_items={this.state.reserved_items}
							/>
						))}


					</div>
					<hr className="seperator-home"></hr>

					<div className="sumup">
						<div className="sumup-container">
							<span className="ammount">{devices}</span>
							<span className="title">Geräte</span>
						</div>
						<div className="sumup-container">
							<span className="ammount">{price} € / Tag</span>
							<span className="title">vsl. Gesamtpreis</span>
						</div>

					</div>
					<center><a
						className={
							this.state.reserved_items.length >= 1
								? "reservate"
								: "reservate disabled"
						}
						onClick={() => this.setState({ page: 1 })}
					>
						Fortfahren
					</a>
					</center>
					<Notification />
				</div>
			) : (
				<div>
					<div className="header">
						<div className="branding">
							<h4 className="title-desktop">Reservierung</h4>
						</div>
					</div>
					<div className="trends-title">
						<hr className="seperator-home"></hr>
					</div>
					<div className="item-list"></div>
					<Notification />
				</div>
			);

		let page_1 =
			this.state.items != null ? (
				<div>
					<div className="header">
						<div className="branding">
							<h4 className="title-desktop">Anfrage</h4>
						</div>
					</div>
					<center>
						<div className="reservation-progress-bar">
							<ProgressBar
								percent={50}
								filledBackground="linear-gradient(to right, #828382, #2ecc71)"
							>
								<Step transition="scale">
									{({ accomplished, index }) => (
										<div
											onClick={() => this.setState({ page: 0 })}
											className="step-container clickable"
										>
											<div
												className={`indexedStep ${accomplished ? "accomplished" : ""
													}`}
											>
												{index + 1}
											</div>
											<span>Geräte</span>
										</div>
									)}
								</Step>
								<Step transition="scale">
									{({ accomplished, index }) => (
										<div className="step-container">
											<div
												className={`indexedStep ${accomplished ? "accomplished" : ""
													}`}
											>
												{index + 1}
											</div>
											<span>Services</span>
										</div>
									)}
								</Step>
								<Step transition="scale">
									{({ accomplished, index }) => (
										<div className="step-container">
											<div
												className={`indexedStep ${accomplished ? "accomplished" : ""
													}`}
											>
												{index + 1}
											</div>
											<span>Abschluss</span>
										</div>
									)}
								</Step>
							</ProgressBar>
						</div>
					</center>
					<div className="trends-title">
						<hr className="seperator-home"></hr>
					</div>
					<div className="item-list">
						<div className="categorie">
							<div className="categorie-header">
								<div class="overlay">
									<h1>Services</h1>
									<span>Wir bieten diverse Services um den optimalen Ablauf deiner Party zu gewährleisten.</span>
									<br />
								</div>
							</div>
							<div className="categorie-body">
								<div className="categorie-body-items">
									{this.state.services.map((service, index) => (
										<Service

											required={this.state.reserved_services.find(x => x.service_id == service.service_id) != null ? true : false}
											remove={(service, success) =>
												this.removeService(service, success)
											}
											reservate={(service, success) =>
												this.reservateService(service, success)
											}
											data={service}
										/>
									))}
								</div>
							</div>
						</div>
					</div>
					<hr className="seperator-home"></hr>
					<div className="sumup">
						<div className="sumup-container">
							<span className="ammount">{devices}</span>
							<span className="title">Geräte</span>
						</div>
						<div className="sumup-container">
							<span className="ammount">
								{price} € / Tag + {price_services}€
							</span>
							<span className="title">vsl. Gesamtpreis</span>
						</div>
					</div>
					<center><a
						className={
							this.state.reserved_items.length >= 1
								? "reservate"
								: "reservate disabled"
						}
						onClick={() => this.setState({ page: 2 })}
					>
						Fortfahren
					</a>
					</center>
					<Notification />
				</div>
			) : (
				<></>
			);


		let page_2 =
			this.state.items != null ? (
				<div>
					<div className="header">
						<div className="branding">
							<h4 className="title-desktop">Anfrage</h4>
						</div>
					</div>
					<center>
						<div className="reservation-progress-bar">
							<ProgressBar
								percent={100}
								filledBackground="linear-gradient(to right, #828382, #2ecc71)"
							>
								<Step transition="scale">
									{({ accomplished, index }) => (
										<div
											onClick={() => this.setState({ page: 0 })}
											className="step-container clickable"
										>
											<div
												className={`indexedStep ${accomplished ? "accomplished" : ""
													}`}
											>
												{index + 1}
											</div>
											<span>Geräte</span>
										</div>
									)}
								</Step>
								<Step transition="scale">
									{({ accomplished, index }) => (
										<div
											onClick={() => this.setState({ page: 1 })}
											className="step-container clickable">
											<div
												className={`indexedStep ${accomplished ? "accomplished" : ""
													}`}
											>
												{index + 1}
											</div>
											<span>Services</span>
										</div>
									)}
								</Step>
								<Step transition="scale">
									{({ accomplished, index }) => (
										<div className="step-container">
											<div
												className={`indexedStep ${accomplished ? "accomplished" : ""
													}`}
											>
												{index + 1}
											</div>
											<span>Abschluss</span>
										</div>
									)}
								</Step>
							</ProgressBar>
						</div>
					</center>
					<div className="trends-title">
						<hr className="seperator-home"></hr>
					</div>
					{this.state.reservated ? 
					<div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
						<h4>Anfrage abgeschlossen</h4>
						<span>Wir melden uns per Email bei dir.</span>
					</div>
					
					:
					<div className="summary">
						<div className="summary-container">
							<div className="summary-title">
								<h4>Geräte / Bundles ({this.state.reserved_items.length})</h4>
							</div>
							<div className="summary-content">
								{this.state.reserved_items.map((item, index) => {
									return (
										<div id={index == 0 ? "first" : index == this.state.reserved_items.length - 1 ? "last" : ""} className="summary-item" key={index}>
											<div className="summary-item-content">
												<h4>{item.name}</h4>
												<p>{item.description}</p>
											</div>
											<div className="summary-item-price">
												<h4>{item.price}€</h4>
											</div>
										</div>
									);
								})}
							</div>
							<div style={{ marginTop: "25px" }} className="summary-title">
								<h4>Services ({this.state.reserved_services.length})</h4>
							</div>
							<div className="summary-content">
								{this.state.reserved_services.length >= 1 ? this.state.reserved_services.map((item, index) => {
									return (
										<div id={index == 0 ? "first" : index == this.state.reserved_services.length - 1 ? "last" : ""} className="summary-item" key={index}>
											<div className="summary-item-content">
												<h4>{item.name}</h4>
												<p>{item.description}</p>
											</div>
											<div className="summary-item-price">
												<h4>{item.price}€</h4>
											</div>
										</div>
									);
								}) : <div className="no-services-container">
									<span className="no-services">Keine Services ausgewählt</span>
								</div>}
							</div>
						</div>
					</div>
					}
					{!this.state.reservated ? 
                    <div>
					<hr className="seperator-home"></hr>
					<div className="sumup">
						<div className="sumup-container">
							<span className="ammount">{devices}</span>
							<span className="title">Geräte</span>
						</div>
						<div className="sumup-container">
							<span className="ammount">
								{price} € / Tag + {price_services}€
							</span>
							<span className="title">vsl. Gesamtpreis</span>
						</div>
					</div>
					<center>
						<div className="button-container">
							<a
								className={
									this.state.reserved_items.length >= 1
										? "reservate"
										: "reservate disabled"
								}
								onClick={() => this.setState({ reservationOpen:true })}
							>
								Anfragen
							</a>
							<span>Die Anfrage ist unverbindlich.</span>
						</div>
					</center>
					</div>
					: <></>
					}
					<Notification />
					<ReservateDialog onReservated={() => this.setState({reservated: true})} showMessage={(message,type) => this.showMessage(message,type)} devices={this.state.reserved_items} services={this.state.reserved_services} isOpen={this.state.reservationOpen} onClose={() => this.setState({reservationOpen:false})}/>
				</div>
			) : (
				<></>
			);

		switch (this.state.page) {
			case 0:
				return page_0;
			case 1:
				return page_1;
			case 2:
				return page_2;
			default:
				return page_0;
		}
	}
}

export default Reservation;
