import { Component } from "react";
import './notification.scss';

var tempNotification = [];
class Notification extends Component {

    state = {
        notif: []
    }

    set(text, type, duration) {
        duration = duration + 250;
        var temp = {nType: type,nText: text,nTimeOut: duration,nShow: true};
        if(tempNotification.length === 0){
        tempNotification.push(temp);
        }
    }


    componentWillUnmount() {
        clearInterval(this.interval);
    }


    componentDidMount() {
        this.interval = setInterval(() => {
            const temp = tempNotification.filter(function(item) {return item.nShow === true;}).map((tempItem, index) => { 
                if(tempItem.nShow === true) {
                    if(tempItem.nTimeOut <= 250) {
                        const updatedItem = {
                            ...tempItem,
                            nShow: false,
                        };
                        return updatedItem;
                    }else {
                        var tempTimeOut = 0;
                        tempTimeOut = tempItem.nTimeOut - 250;

                        const updatedItem = {
                            ...tempItem,
                            nTimeOut: tempTimeOut,
                        };
                        return updatedItem;
                    }
                }
                return tempItem;
            });
            tempNotification = temp ;
            this.setState({notif: tempNotification});
        }, 250);
     }


    render() {
            return (
                <div>
                    {tempNotification.map((item, index) => 
                        <div id="notificationBar" key={index} className={item.nShow? "notificationBar showNotification " + item.nType : "notificationBar "+ item.nType + " noShow"}>{item.nText}</div> 
                    )}
                </div>
            );
        
    }
 }

 export default Notification;