import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.scss'
import React, { Suspense } from 'react';
import TestPage from './pages/Test/test';
import Reservation from './pages/Reservation/reservation';

class App extends React.Component {

  render() {
    return (
        <Suspense fallback="">
          <Router>
            <Switch>
              <Route exact path='/' render={() => <Reservation />} />
              <Route path={'*'} render={() => <div>404</div>} />
            </Switch>
          </Router>
        </Suspense>
    );
    //<Route exact path='/battle/create' render={(props) => !this.state.isLogin? <Login isLogin={this.handleLogin}/> : <BattleCreateDialog />} />
  }



}


export default App;
