import React from "react";
import "./test.scss";

class TestPage extends React.Component {
	render() {
		return (
			<center>
				<div className="categorie-header">
					<div class="overlay">
						<h1>Lautsprecher</h1>
						<p>Beschreibung</p>
						<br />
						<button>READ MORE</button>
					</div>
				</div>
			</center>
		);
	}
}

export default TestPage;
