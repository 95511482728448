import React from "react";
import "../../../Dialogs/Dialog.scss";
import "./ReservateDialog.scss";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import moment from "moment";
import "moment/locale/de";
import {
	Modal,
	Input,
	TimePicker,
	DatePicker,
	Textarea,
	Select,
	PhoneInput,
} from "react-rainbow-components";
import { CloseButton, Form } from "react-bootstrap";
import {
	Tabset,
	Tab,
	ButtonIcon,
	Button,
	Table,
	Column,
	Badge,
	Avatar,
	CounterInput,
	Option,
	MultiSelect,
	Picklist,
} from "react-rainbow-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";

import APIHandler from "../../../../Handler/APIHandler";



const badgeStyles = {
	color: "rgba(1,182,245,1)",
	marginLeft: "0.2rem",
	cursor: "default",
};

const StatusBadge = ({ value }) => (
	<Badge label={value} variant="lightest" style={badgeStyles} />
);

class ReservateDialog extends React.Component {
	state = {
		counter: 1,
		order: { name: "option 1", label: "Tischnummer" },
		expanded: false,
		phone: {
			isoCode: "de",
		},
		forname: "",
		lastname: null,
		email: "",
		note: "",
		date: new Date()
	};

	constructor(props) {
		super(props);
	}

	async componentDidMount() { }

	componentWillReceiveProps() { }

	onClose = (e) => {
		this.props.onClose(e);
	};

	reservate() {

		if (this.validateInputs()) {

			var requestOptions = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					reservation: {
						devices: this.props.devices,
						services: this.props.services,
						note: this.state.note,
						date: this.state.date
					},
					customer: {
						email: this.state.email,
						surname: this.state.lastname,
						forname: this.state.forname,
						phone_number: this.state.phone.countryCode + this.state.phone.phone,
					}
				}),
			};

			fetch(APIHandler.URL + "/devices/reservate", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					if (result) {
						this.props.showMessage("Die Anfrage wurde erfolgreich abgeschlossen", 'success');
						this.props.onClose();
						this.props.onReservated(JSON.parse(requestOptions.body));
					} else {
						this.props.showMessage("Die Anfrage konnte nicht abgeschlossen werden", 'error');
					}
				})
				.catch((error) => console.log("error", error));

		} else {
			this.props.showMessage("Bitte füllen sie alle Pflichtfelder korrekt aus", "error");
		}
	}

	emailIsValid(email) {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	}

	validateInputs() {
		if (this.state.lastname != null && this.emailIsValid(this.state.email)) {
			return true;
		}
		console.log(this.state.lastname);
		return false;
	}

	render() {
		const { selected } = this.state;

		return (
			<AnimatePresence>
				{this.props.isOpen && (
					<>
						<motion.div
							initial={{
								opacity: 0,
								y: -200,
							}}
							animate={{
								opacity: 1,
								y: 0,
								transition: {
									duration: 0.3,
								},
							}}
							exit={{
								opacity: 0,
							}}
							className="modal-backdrop"
						>
							<motion.div
								className="dialog"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
							>
								<div className="dialog-header">
									<div className='dialog-header-details'>
										<div className="dialog-header-title">
											<span>Anfragen</span>
										</div>
										<div className="dialog-header-date">
											<span>Unverbindlich</span>
										</div>
									</div>
									<div className="dialog-header-close">
										<ButtonIcon onClick={() => this.onClose()} variant="border-filled" icon={<FontAwesomeIcon icon={faXmark} />} />
									</div>
								</div>
								<div id="scroll" className="dialog-body">
									<div className="dialog-body-content">
										<div className="inputs">
											<DatePicker
												formatStyle="large"
												value={this.state.date}
												label="Datum"
												onChange={value => this.setState({ date: value })}
												required={true}
											/>
											<Input
												label="Nachname"
												placeholder="Mustermann"
												onChange={(value) => this.setState({ lastname: value.target.value })}
												type="text"
												required={true}
												className="rainbow-p-around_medium"
											/>
											<Input
												label="Vorname"
												placeholder="Max"
												onChange={(value) => this.setState({ forname: value.target.value })}
												type="text"
												required={false}
												className="rainbow-p-around_medium"
											/>
											<PhoneInput
												label="Telefonnummer"
												placeholder="1234 5678910"
												onChange={(value) => this.setState({ phone: value }, () => console.log(this.state.phone))}
												value={this.state.phone}
												required={false}
												bottomHelpText="Für eventuelle Rückfragen benötigen wir Ihre Telefonnummer"
											/>
											<Input
												label="Email"
												placeholder="max.m@feedboost.de"
												type="email"
												required={true}
												onChange={(value) => this.setState({ email: value.target.value })}
												pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
												className="rainbow-p-around_medium"
												bottomHelpText="Wir melden uns per Email bei Ihnen"
											/>
											<Textarea
												label="Anmerkungen"
												bottomHelpText="Hier können Sie zusätzliche Anmerkungen hinterlassen."
												placeholder="Zusätzliche Anmerkungen"
												onChange={(value) => this.setState({ note: value.target.value })}
												rows={4}
												className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
											/>
											<center>
												<Button
													style={{ marginTop: "15px", marginBottom: "15px" }}
													label="Anfrage abschließen"
													onClick={() => this.reservate()}
													variant="brand"
													className="rainbow-m-around_medium"
												/>
											</center>
										</div>
									</div>
								</div>
							</motion.div>
						</motion.div>
					</>
				)}

			</AnimatePresence>
		);
	}
}

export default ReservateDialog;
