import React from "react";
import "./subcategorie.scss";
import Badge from "react-bootstrap/Badge";
import APIHandler from "../../../Handler/APIHandler";
import Item from "./item";

class SubCategorie extends React.Component {
	state = {
		expanded: false,
	};

	componentDidMount() {
		this.setState({
			available_count: this.props.data.available,
		});

		console.log(this.props.data.devices[0]);
	}

	render() {
		return (
			<div className="sub-categorie">
				<div className="sub-categorie-header">
					<span>{this.props.data.name}</span>
				</div>
				<div className="sub-categorie-body">
					<div className="sub-categorie-body-items">
					{this.props.data.devices.map((device) => (
							<Item 
							reservate={(item, success) => this.props.reservate(item, success)} 
							unreservate={(item) => this.props.unreservate(item)}
							last={this.props.last ? this.props.data.devices.length - 1 === this.props.data.devices.indexOf(device) : false}
							data={device.device_data[0]} 
							reserved_items = {this.props.reserved_items}
							
							/>
						))}
					</div>
				</div>
			</div>
		);
	}
}

export default SubCategorie;
