import React from 'react';
import './item.scss'
import Badge from 'react-bootstrap/Badge';
import APIHandler from '../../../Handler/APIHandler';

class Service extends React.Component {


    state = {
        reservable: true,
        reserved: false,
        removable: true,
    }


    componentDidMount() {
        if(this.props.required) {
            this.setState({reserved: true, removable: false});
        }
    }


    reservate = () => {

        if (!this.state.reserved) {
            this.setState({
                reserved: true
            })
            this.props.reservate(this.props.data, true);
        }
    }

    remove = () => {
        if (this.state.reserved) {
            this.setState({
                reserved: false
            })
            this.props.remove(this.props.data, true);
        }
    }

    render() {

        return (
            <div className={this.state.reserved ? "item-container service selected" : "item-container service"}>
                <div className="banner-cover" style={{ backgroundImage: `url(` + this.props.data.image_url + `)` }}> </div>
                <div className='item-content'>
                    <div className='header'>
                        <span>{this.props.data.name}</span>
                    </div>
                    <div className='details'>
                        <div className='description'>
                            <span>{this.props.data.description}</span>
                        </div>
                    </div>
                    <div className='booking'>
                        <div className='reservate-button'>
                            { this.state.removable ? this.state.reserved ? <a className='remove' onClick={() => this.remove()}>Entfernen</a> :<a className={this.state.reservable ? 'reservate' : 'reservate disabled'} onClick={() => this.reservate()}>Hinzufügen</a> : <a className='required'>Pflicht</a>}
                            <span>{this.props.data.price} {this.props.data.price_suffix}</span>
                        </div>


                    </div>
                </div>
            </div>
        )
    }

}

export default Service;