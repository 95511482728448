import React from 'react';
import './item.scss'
import Badge from 'react-bootstrap/Badge';
import APIHandler from '../../../Handler/APIHandler';

class Item extends React.Component {


    state = {
        reserved: false,
        reserved_ammount: 0,
        available_count: 2,
        reservable: true,
    }


    reservate = () => {


        if(!this.state.reservable){
            return;
        }

        if (this.state.available_count <= 0) {
            this.setState({ reservable: false });
            this.props.reservate({ name: 'Alto T18' }, false);
            return;
        }

        this.props.reservate(this.props.data, true);

        this.setState({
            reserved: true,
            reserved_ammount: this.state.reserved_ammount + 1,
            available_count: this.state.available_count - 1,
            reservable: this.state.available_count > 1
        })
    }

    unreservate = () => {

        this.props.unreservate(this.props.data);

        if (this.state.reserved_ammount <= 1) {
            this.setState({
                reserved: false,
                reserved_ammount: 0,
                available_count: this.props.data.available,
                reservable: this.props.data.available >= 1
            })
            return;
        }

        this.setState({
            available_count: this.state.available_count + 1,
            reserved_ammount: this.state.reserved_ammount - 1,
            reservable: this.state.available_count + 1 >= 1
        })



    }


    componentDidMount() {
        console.log(this.props.reserved_items);

        let reserved_ammount = this.props.reserved_items.filter(item => item.device_id == this.props.data.device_id).length;
        let reservable = true
        if (this.props.data.available - reserved_ammount < 1 || (this.props.data.bundle && this.props.reserved_items.filter(item => item.bundle).length >= 1)) {
            reservable = false;
        }

        this.setState({
            reserved: this.props.reserved_items.includes(this.props.data),
            reserved_ammount: reserved_ammount,
            available_count: this.props.data.available - reserved_ammount,
            reservable: reservable
        })
    }


    componentDidUpdate() {
        if (this.state.reservable && this.props.data.bundle && this.props.reserved_items.filter(item => item.bundle).length >= 1) {
            this.setState({ reservable: false });
        } else {
            if (this.state.reservable == false && this.props.reserved_items.filter(item => item.bundle).length == 0 && this.state.available_count >= 1) {
                this.setState({ reservable: true });
            }
        }
    }


    render() {

        return (
            <div style={{ borderBottomLeftRadius: this.props.last ? "21px" : "0", borderBottomRightRadius: this.props.last ? "21px" : "0" }} className={this.state.reserved ? "item-container selected" : "item-container"}>
                <div className="banner" style={{ backgroundImage: `url(` + this.props.data.image_url + `)`, backgroundSize: this.props.data.image_style == 'COVER' ? "cover" : "contain" }}> </div>
                <div className='item-content'>
                    <div className='header'>
                        <span>{this.props.data.name}</span>
                    </div>
                    <div className='details'>
                        <div className='description'>
                            <span>{this.props.data.description}</span>
                        </div>
                        <div className='data'>
                            {this.props.data.data.map((data, index) =>
                                <div className='detail'>
                                    <span>{data.name}: </span><span style={{ fontWeight: "bold" }}>{data.value}</span>
                                </div>
                            )
                            }
                        </div>
                    </div>
                    <div className='booking'>
                        <div className='available-container'>
                            <span className='ammount'>{this.state.available_count}</span>
                            <span className='title'>Verfügbar</span>
                        </div>
                        <div className='reservate-button'>

                            {this.state.reserved ?
                                <div className='btn-container'>
                                    <a className={this.state.reserved_ammount > 1 ? 'btn-minus' : 'btn-minus disabled'} onClick={() => this.unreservate()}>-1</a>
                                    <a className={this.state.reservable ? 'btn-plus' : 'btn-plus disabled'} onClick={() => this.reservate()}>+1</a>
                                </div>
                                :
                                <a className={this.state.reservable ? 'reservate' : 'reservate disabled'} onClick={() => this.reservate()}>Hinzufügen</a>
                            }


                            <span>{this.props.data.price} € / Tag</span>
                        </div>

                        <div className='available-container'>
                            <span className='ammount'>{this.state.reserved_ammount}</span>
                            <span className='title'>Reserviert</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Item;